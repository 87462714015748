import { put, takeLatest } from "redux-saga/effects";
import TransporterIncomingRidesActions, {
  TransporterIncomingRideTypes,
} from "reducers/ride/transporter-incoming-rides";
import OnGoingRideActions from "reducers/transporter/transporter-ongoing-rides";
import _ from "lodash";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* incomingRidesAsyncRequest({ page, limit, filter = "" }) {
  const query = `
    query {
      TransporterInComingRides(page:${page},limit:${limit},filter:"${filter}"){ 
        page
        limit
        totalPages 
        totalDocs
        rides { 
          _id
          status
          vehicleCategory
          rideNumber
          vehicleOptions
          createdAt
          pickup{address latitude longitude}
          dropoff{address latitude longitude}  
          datetime
          samu { name }
          patient {
            firstname
						lastname
            nss
          }
          service{company{name}}
          note
          isRoundTrip
          files { path name extension }
          dispatchingCycle
          nextDispatchingAt
        }
      }
    }
  `;

  try {
    const data = yield graphQLClient().request(query);
    const rides = data.TransporterInComingRides;

    yield put(
      TransporterIncomingRidesActions.transporterIncomingRidesRequestSuccess(
        rides
      )
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterIncomingRidesActions.transporterIncomingRidesRequestFailure
    );
  }
}

function* transporterAcceptProposedRideAsyncRequest({
  ride,
  vehicle,
  arriveIn,
  /*driver, */ callback,
}) {
  // let vehicleQuery = null;

  // if (vehicle !== false) {
  //   vehicleQuery = `
  //   {
  //     id: "${vehicle._id}",
  //     plate: "${vehicle.plate}",
  //     make: "${vehicle.make}",
  //     model: "${vehicle.model}",
  //     category: ${vehicle.category}
  //   }
  // `;
  // }

  const query = `
    mutation {
      TransporterAcceptProposedRide(
        rideId: "${ride}",
        vehicleId:${vehicle},
        arriveIn: ${arriveIn}
      )
      {
        _id
        patient {
          firstname
          lastname
          phone
          email
          nss
          status
        }
        pickup{address}
        dropoff{address}
        status
        rideNumber
        transporter {
          vehicle {
            plate
            category
            make
            color
            model
          }
        }
        datetime
        vehicleOptions
        service{company{name}}
      }
    }
  `;

  try {
    const { TransporterAcceptProposedRide } = yield graphQLClient().request(
      query
    );

    yield put(
      TransporterIncomingRidesActions.transporterAcceptProposedRideRequestSuccess(
        TransporterAcceptProposedRide
      )
    );

    yield put(
      OnGoingRideActions.onGoingRidesNewRideRequest(
        TransporterAcceptProposedRide
      )
    );

    if (_.isFunction(callback)) callback({});
  } catch (error) {
    const { message = "" } = error.response.errors[0];

    if (message === "RideNotAvailable") {
      yield put(
        TransporterIncomingRidesActions.transporterProposedRideRequestNotAvailable(
          ride
        )
      );
      if (_.isFunction(callback)) callback({ error: message });
    } else {
      yield put(
        TransporterIncomingRidesActions.transporterAcceptProposedRideRequestFailure(
          message
        )
      );
    }
  }
}

function* transporterRequestDatetimeValidationForProposedRideAsyncRequest({
  ride,
  datetimeOffer,
  /*driver, */ callback,
}) {
  const query = `
    mutation {
      TransporterRequestDatetimeValidationForProposedRide(
        rideId: "${ride}",
        datetimeOffer: "${datetimeOffer}"
      )
        {
          _id
          status
          patient{firstname lastname}
        }
    }
  `;
  try {
    const data = yield graphQLClient().request(query);
    const ride = data.TransporterRequestDatetimeValidationForProposedRide;

    yield put(
      TransporterIncomingRidesActions.transporterRequestDatetimeValidationForProposedRideSuccess(
        ride
      )
    );

    if (_.isFunction(callback)) callback({});
  } catch (error) {
    const { message = "" } = error.response.errors[0];

    yield put(
      TransporterIncomingRidesActions.transporterRequestDatetimeValidationForProposedRideFailure(
        message
      )
    );
  }
}

function* transporterRefuseIncomingRideAsyncRequest({ ride, callback }) {
  const query = `
    mutation {
      TransporterRefuseIncomingRide(rideId: "${ride._id}") {
        _id
      }
    }
  `;
  try {
    const data = yield graphQLClient().request(query);
    const ride = data.TransporterRefuseIncomingRide;

    yield put(
      TransporterIncomingRidesActions.transporterRefuseIncomingRideSuccess(ride)
    );

    if (_.isFunction(callback)) callback({});
  } catch (error) {
    const { message = "" } = error.response.errors[0];

    yield put(
      TransporterIncomingRidesActions.transporterRefuseIncomingRideFailure(
        message
      )
    );
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    TransporterIncomingRideTypes.TRANSPORTER_INCOMING_RIDES_REQUEST,
    incomingRidesAsyncRequest
  ),
  takeLatest(
    TransporterIncomingRideTypes.TRANSPORTER_ACCEPT_PROPOSED_RIDE_REQUEST,
    transporterAcceptProposedRideAsyncRequest
  ),
  takeLatest(
    TransporterIncomingRideTypes.TRANSPORTER_REQUEST_DATETIME_VALIDATION_FOR_PROPOSED_RIDE_REQUEST,
    transporterRequestDatetimeValidationForProposedRideAsyncRequest
  ),
  takeLatest(
    TransporterIncomingRideTypes.TRANSPORTER_REFUSE_INCOMING_RIDE_REQUEST,
    transporterRefuseIncomingRideAsyncRequest
  ),
];
