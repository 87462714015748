import { Component } from "react";
import {
  Box,
  Tooltip,
  HStack,
  Stack,
  VStack,
  Center,
  Select,
  CheckIcon,
} from "native-base";
import _ from "lodash";
import moment from "moment";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

import { Button, Text, Tag, NotSpecified } from "components";
import clockIcon from "assets/img/clock.svg";

export function formatPartenaryTime(time) {
  return _.isInteger(time) ? moment.utc(time * 1000).format("mm:ss") : "--:--";
}

class InComingRidesCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startTime: new Date(this.props.ride.datetime),
      partenaryTimeLabel: "start",
      partenaryTime: null,
    };

    this.onAccept = this.onAccept.bind(this);
    this.onRefuse = this.onRefuse.bind(this);
    this.setStartTime = this.setStartTime.bind(this);
  }

  componentDidMount() {
    this.timer = null;
    const { dispatchingCycle, nextDispatchingAt } = this.props.ride;
    if (dispatchingCycle === "first") {
      const partenaryTime = moment(nextDispatchingAt).diff(
        moment(new Date()),
        "seconds"
      );
      this.setState({ partenaryTime });
      this.timer = setInterval(() => {
        this.setState({ partenaryTime: this.state.partenaryTime - 1 });
        if (this.state.partenaryTime <= 0) clearInterval(this.timer);
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  onAccept(arriveIn) {
    const { onPressAcceptRideButton, ride } = this.props;
    if (typeof arriveIn === "string") arriveIn = parseInt(arriveIn, 10);
    else arriveIn = null;
    onPressAcceptRideButton({
      ...ride,
      // startTime: this.state.startTime,
      arriveIn,
    });
  }

  onRefuse() {
    const { onPressRefuseRideButton, ride } = this.props;
    onPressRefuseRideButton(ride);
  }

  setStartTime(time) {
    this.setState({
      startTime: time,
    });
  }

  render() {
    const { t, i18n, ride } = this.props;
    const {
      pickup,
      dropoff,
      datetime,
      vehicleCategory,
      vehicleOptions,
      status,
      service,
      createdAt,
      samu,
      note,
      isRoundTrip,
      rideNumber,
    } = ride;
    const anOffer = status === "anoffer";
    const startNow = moment(datetime).diff(moment(new Date()), "minutes") < 30;

    return (
      <Stack
        mb={this.props.mb || 0}
        position="relative"
        borderWidth={1}
        boxSize="border-box"
        rounded="4px"
        borderColor="gray"
        _hover={{ borderColor: "rgba(2, 150, 174, 0.6)" }}
        _pressed={{ borderColor: "#0296AE" }}
        alignItems="center"
        maxWidth="986px"
        py="2"
        px="27px"
        bg="ligth"
      >
        <HStack w="full">
          <VStack space={4} w="295px" pr={2}>
            <HStack space="40px">
              <VStack space={5} h="110px">
                <Text fontSize="16px" fontWeight={900}>
                  {rideNumber}
                </Text>
                <Text fontSize="16px" fontWeight={700}>
                  {moment(datetime).format("DD MMM HH:mm")}
                </Text>
                <HStack justifyContent="space-between" w="full">
                  <img src={clockIcon} style={{ width: 25 }} alt="" />
                  <Text fontSize="16px" fontWeight={900}>
                    {formatPartenaryTime(this.state.partenaryTime)}
                  </Text>
                </HStack>
              </VStack>

              <VStack space={2} flex={1}>
                <VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Prise en charge
                  </Text>
                  <Text fontSize="12px" fontWeight="500" numberOfLines={2}>
                    {pickup.address}
                  </Text>
                </VStack>
                <VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Dépose
                  </Text>

                  {dropoff.address ? (
                    <Text
                      nbOfLines={2}
                      fontSize="12px"
                      fontWeight="500"
                      numberOfLines={2}
                    >
                      {dropoff.address}
                    </Text>
                  ) : (
                    <NotSpecified />
                  )}
                </VStack>
              </VStack>
            </HStack>

            {/* {isShowMoreInfo && samu && (
              <VStack space={1} pt={2} borderTopWidth={1} borderColor='gray'>
                <Text fontSize='12px' fontWeight='600' color='gray'>
                  Informations complémentaires du lieu d'intervention
                </Text>

                <HStack space={4} w='full'>
                  <VStack flex={1}>
                    <Text fontSize='12px' fontWeight='500' color='gray'>
                      Batiment
                    </Text>
                    <Text fontSize='12px' fontWeight='500' isTruncated>
                      {intervention.batiment}
                    </Text>
                  </VStack>

                  <VStack flex={1}>
                    <Text fontSize='12px' fontWeight='500' color='gray'>
                      Digit Code
                    </Text>
                    <Text fontSize='12px' fontWeight='500' isTruncated>
                      {intervention.digicode}
                    </Text>
                  </VStack>

                  <VStack flex={1}>
                    <Text fontSize='12px' fontWeight='500' color='gray'>
                      Étage
                    </Text>
                    <Text fontSize='12px' fontWeight='500' isTruncated>
                      {intervention.etage}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            )} */}
          </VStack>

          <VStack
            space={1}
            flex={1}
            pl={4}
            borderLeftWidth={1}
            borderColor="gray"
          >
            <HStack space="44px">
              <VStack space={1} w="132px">
                <Text fontSize="12px" fontWeight="500" color="gray">
                  Notes
                </Text>
                {_.isEmpty(note) && <NotSpecified />}
                <Text fontSize="12px" fontWeight="500">
                  {note || ""}
                </Text>
              </VStack>

              <VStack space={2} h="full">
                <Text fontSize="12px" fontWeight="500" color="gray">
                  Tags
                </Text>
                <HStack space={3}>
                  {isRoundTrip && (
                    <Tippy
                      content={
                        <Text fontSize="12px" fontWeight="500" color="white">
                          Transport aller/retour
                        </Text>
                      }
                      placement="bottom"
                      hideOnClick={false}
                      duration={0}
                    >
                      <Center>
                        <Tag
                          cursor="pointer"
                          text="A/R"
                          color={"white"}
                          bg={"secondary"}
                          py="2px"
                        />
                      </Center>
                    </Tippy>
                  )}
                  {startNow && (
                    <Tippy
                      content={
                        <Text fontSize="12px" fontWeight="500" color="white">
                          Départ dans les 30 minutes !
                        </Text>
                      }
                      placement="bottom"
                      hideOnClick={false}
                      duration={0}
                    >
                      <Center>
                        <Tag
                          cursor="pointer"
                          text="Immediat"
                          color={"white"}
                          bg={"info"}
                          py="2px"
                        />
                      </Center>
                    </Tippy>
                  )}
                  {samu && (
                    <Tag
                      text={"SAMU"}
                      color={"white"}
                      bg={"secondary"}
                      py="2px"
                    />
                  )}
                  {anOffer && (
                    <Tag
                      text="An offer"
                      color={"white"}
                      bg={"secondary"}
                      py="2px"
                    />
                  )}
                  <Tippy
                    content={
                      <Text fontSize="12px" fontWeight="500" color="white">
                        Catégorie de véhicule demandée
                      </Text>
                    }
                    placement="bottom"
                    hideOnClick={false}
                    duration={0}
                  >
                    <Center>
                      <Tag
                        cursor="pointer"
                        text={_.capitalize(vehicleCategory)}
                        color={"white"}
                        bg={`vehicleCategory.${vehicleCategory}`}
                        py="2px"
                      />
                    </Center>
                  </Tippy>
                </HStack>
              </VStack>

              <Box flex={1}>
                <Box alignItems="flex-end" justifyContent="center" flex={1}>
                  <VStack
                    justifyContent="center"
                    h="full"
                    space="14px"
                    flex={1}
                  >
                    {startNow ? (
                      <Select
                        w="90px"
                        h="27.2px"
                        variant="primary"
                        accessibilityLabel={t(`button.accept`, {
                          lng: i18n.language,
                        }).toUpperCase()}
                        placeholder={t(`button.accept`, {
                          lng: i18n.language,
                        }).toUpperCase()}
                        onValueChange={(val) => this.onAccept(val)}
                        padding="0"
                        pl={2}
                      >
                        <Select.Item label="15 min" value="15" />
                        <Select.Item label="30 min" value="30" />
                        <Select.Item label="45 min" value="45" />
                        <Select.Item label="60 min" value="60" />
                      </Select>
                    ) : (
                      <Button
                        isDisabled={anOffer}
                        onPress={this.onAccept}
                        rounded="5px"
                      >
                        {t(`button.accept`, {
                          lng: i18n.language,
                        }).toUpperCase()}
                      </Button>
                    )}

                    <Button
                      secondary
                      isDisabled={anOffer}
                      onPress={this.onRefuse}
                      rounded="5px"
                    >
                      {t(`button.decline`, {
                        lng: i18n.language,
                      }).toUpperCase()}
                    </Button>
                  </VStack>
                </Box>
              </Box>
            </HStack>
          </VStack>
        </HStack>
      </Stack>
    );
  }
}

export default InComingRidesCard;
